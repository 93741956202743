import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Button, TextField, Typography, Avatar, Container, Alert } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import SupervisedUserCircleOutlinedIcon from "@mui/icons-material/SupervisedUserCircleOutlined";
import { AuthContext } from "../../context/authContext";
import Topbar from "../global/TopBar";

const Register = () => {
  const taskSchema = yup.object().shape({
    first_name: yup.string().required("Primeiro nome é obrigatório!"),
    last_name: yup.string().required("Último nome é obrigatório!"),
    email: yup.string().email("Endereço de email inválido!").required("O título da tarefa é obrigatório!"),
    pin1: yup
      .number()
      .typeError("O PIN deve ser um número")
      .integer("O PIN deve ser um número inteiro")
      .positive("O PIN deve ser um número positivo")
      .min(0, "O PIN deve estar entre 0 e 9")
      .max(9, "O PIN deve estar entre 0 e 9")
      .required("O PIN é obrigatório!"),
    pin2: yup
      .number()
      .typeError("O PIN deve ser um número")
      .integer("O PIN deve ser um número inteiro")
      .positive("O PIN deve ser um número positivo")
      .min(0, "O PIN deve estar entre 0 e 9")
      .max(9, "O PIN deve estar entre 0 e 9")
      .required("O PIN é obrigatório!"),
    pin3: yup
      .number()
      .typeError("O PIN deve ser um número")
      .integer("O PIN deve ser um número inteiro")
      .positive("O PIN deve ser um número positivo")
      .min(0, "O PIN deve estar entre 0 e 9")
      .max(9, "O PIN deve estar entre 0 e 9")
      .required("O PIN é obrigatório!"),
    pin4: yup
      .number()
      .typeError("O PIN deve ser um número")
      .integer("O PIN deve ser um número inteiro")
      .positive("O PIN deve ser um número positivo")
      .min(0, "O PIN deve estar entre 0 e 9")
      .max(9, "O PIN deve estar entre 0 e 9")
      .required("O PIN é obrigatório!"),
    pin5: yup
      .number()
      .typeError("O PIN deve ser um número")
      .integer("O PIN deve ser um número inteiro")
      .positive("O PIN deve ser um número positivo")
      .min(0, "O PIN deve estar entre 0 e 9")
      .max(9, "O PIN deve estar entre 0 e 9")
      .required("O PIN é obrigatório!"),
    pin6: yup
      .number()
      .typeError("O PIN deve ser um número")
      .integer("O PIN deve ser um número inteiro")
      .positive("O PIN deve ser um número positivo")
      .min(0, "O PIN deve estar entre 0 e 9")
      .max(9, "O PIN deve estar entre 0 e 9")
      .required("O PIN é obrigatório!"),
  });

  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    } else {
    }
  }, [currentUser]);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    pin1: null,
    pin2: null,
    pin3: null,
    pin4: null,
    pin5: null,
    pin6: null,
  };

  const config = {
    headers: {
      "access-token": process.env.REACT_APP_ACCESS_TOKEN,
      "Content-Type": "application/json", // Adjust the content type if needed
    },
    withCredentials: true,
  };

  const handleFormSubmit = async (values) => {
    try {
      let pin = Object.values(values).slice(-6).join("");
      // console.log(pin);
      // console.log(values.pin);

      let valores = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        pin: parseInt(pin),
      };

      const response = await axios.post(process.env.REACT_APP_API_CALL + "users/register", valores, config);

      if (response.data.success !== 1) {
        console.log("error fatal register");
        return;
      } else {
        console.log("success");
        console.log(response.data.data.insertId);

        const employeeId = response.data.data.insertId;

        // Create calendars for multiple years
        const years = [2025, 2026, 2027, 2028];
        for (const year of years) {
          const startDate = `${year}-01-01`;
          const endDate = `${year}-12-31`;

          const createCalendar = await axios.post(
            process.env.REACT_APP_API_CALL + `calendar/${employeeId}`,
            { startDate, endDate },
            config
          );

          if (createCalendar.data.success !== 1) {
            console.log(`error fatal createCalendar for year ${year}`);
            return;
          } else {
            console.log(`success createCalendar for year ${year}`);
          }
        }

        // Create management table
        let data = [
          {
            employee_id: employeeId,
            work_type: "Home",
            entrance_time: "9:00",
            minimum_hours: 8,
          },
          {
            employee_id: employeeId,
            work_type: "Office",
            entrance_time: "9:00",
            minimum_hours: 8,
          },
          {
            employee_id: employeeId,
            work_type: "Outdoors",
            entrance_time: "9:00",
            minimum_hours: 8,
          },
        ];

        const createManagementTable = await axios.post(process.env.REACT_APP_API_CALL + `management/`, data, config);

        if (createManagementTable.data.success !== 1) {
          console.log("error fatal createManagementTable");
          return;
        } else {
          console.log("success");

          let inventoryData = {
            employee_id: employeeId,
            bank: 0,
            tokens: 10,
          };

          const createInventory = await axios.post(process.env.REACT_APP_API_CALL + `inventory/`, inventoryData, config);

          if (createInventory.data.success !== 1) {
            console.log("error fatal createInventory");
            return;
          } else {
            console.log("success");

            setTimeout(() => {
              navigate("/");
            }, 1000);
          }
        }
      }
    } catch (error) {
      if (error.response && error.response.data.auth === false) {
        console.error("Session error");
      } else {
        console.error("General error", error);
      }
    }
  };

  return (
    <>
      <Topbar />
      <Container component="main" maxWidth="xs">
        <Box display="flex" flexDirection="column" alignItems="center">
          <>
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <SupervisedUserCircleOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h2">
              REGISTO DE EMPREGADO
            </Typography>

            <Formik
              onSubmit={handleFormSubmit}
              initialValues={initialValues}
              validationSchema={taskSchema}
              validateOnChange={false}
              validateOnBlur={true}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    fullWidth
                    variant="filled"
                    type="text"
                    label={"Primeiro Nome"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.first_name}
                    name="first_name"
                    error={!!touched.first_name && !!errors.first_name}
                    helperText={touched.first_name && errors.first_name}
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    // sx={style}
                  />

                  <TextField
                    margin="normal"
                    fullWidth
                    variant="filled"
                    type="text"
                    label={"Último Nome"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.last_name}
                    name="last_name"
                    error={!!touched.last_name && !!errors.last_name}
                    helperText={touched.last_name && errors.last_name}
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    // sx={style}
                  />

                  <TextField
                    margin="normal"
                    fullWidth
                    variant="filled"
                    type="text"
                    label={"Endereço de email"}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                    name="email"
                    error={!!touched.email && !!errors.email}
                    helperText={touched.email && errors.email}
                    inputProps={{ style: { fontSize: 16 } }}
                    InputLabelProps={{ style: { fontSize: 16 } }}
                    // sx={style}
                  />

                  <Typography component="h1" variant="h2" textAlign={"center"} marginTop={"50px"}>
                    Código Pin
                  </Typography>
                  <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" mb={2}>
                    {[1, 2, 3, 4, 5, 6].map((index) => (
                      <TextField
                        key={index}
                        margin="normal"
                        variant="filled"
                        type="text" // Change type to "text"
                        inputMode="numeric" // Use inputMode numeric to display numeric keyboard
                        name={`pin${index}`}
                        onBlur={handleBlur}
                        onKeyDown={(e) => {
                          const allowedKeyCodes = [8, 9, 37, 39]; // Allow backspace, tab, left arrow, and right arrow
                          if (allowedKeyCodes.includes(e.keyCode)) return;
                          if (e.keyCode < 48 || e.keyCode > 57) {
                            e.preventDefault(); // Prevent input if it's not a number
                          }
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value.slice(-1); // Take only the last character
                          handleChange({ target: { name: e.target.name, value: inputValue } });
                          if (inputValue && index < 6) {
                            const nextField = document.querySelector(`#pin${index + 1}`);
                            if (nextField) {
                              nextField.focus();
                            }
                          }
                        }}
                        value={values[`pin${index}`]}
                        style={{ width: "15%", flexBasis: "15%" }}
                        inputProps={{ maxLength: 1, inputMode: "numeric" }}
                        id={`pin${index}`}
                      />
                    ))}
                  </Box>

                  {Object.keys(errors).some((key) => key.startsWith("pin") && touched[key] && errors[key]) && (
                    <Alert variant="outlined" severity="error">
                      {
                        Object.keys(errors)
                          .filter((key) => key.startsWith("pin") && touched[key] && errors[key])
                          .map((key) => errors[key])[0]
                      }
                    </Alert>
                  )}

                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 3,
                      mb: 2,
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold">
                      Registar Empregado
                    </Typography>
                  </Button>
                </form>
              )}
            </Formik>
          </>
        </Box>
      </Container>
    </>
  );
};

export default Register;
